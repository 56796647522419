body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  background-color: #090909;
  color: #ffffff;
  margin: 0;
  overflow-x: hidden;
}

::placeholder {
  color: #ffffff;
}

:focus-visible {
  outline: none !important;
}

/* .css-1nqica1-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.23) !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
  color: #ffffff;
}

.admin.active {
  background-color: #df8b3b;
  border-radius: 4px;
}

.active p {
  color: #df8b3b;
  position: relative;
}

.active p::after {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #df8b3b;
  content: '';
  bottom: -5px;
  left: 0;
}

.PhoneInput {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  height: 54px;
  width: 170px;
  padding: 0;
  border-radius: 8px;
}

.PhoneInputCountrySelect {
  z-index: 0 !important;
}

.PhoneInputCountry {
  margin-right: 0 !important;
  width: 170px;
  justify-content: space-between;
  padding: 0 20px;
}

.PhoneInput .PhoneInputCountry .PhoneInputCountryIcon--border {
  box-shadow: none;
  background-color: transparent;
  width: 30px;
  height: 20px;
  border: none;
  box-shadow: none !important;
}

.PhoneInput input {
  background-color: transparent;
  border: none;
  color: rgb(255, 255, 255);
  padding: 0;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  width: 40%;
}

.PhoneInputCountrySelectArrow {
  opacity: 1 !important;
  color: white !important;
}

.PhoneInput input:focus {
  outline: none;
}

.custom-selected-option {
  color: white !important;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  height: 100vh;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.MuiPickersCalendarHeader-label {
  color: #ffffff;
}

.css-1v1ju4q-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton,
.css-qpgk5w-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
.css-1o8wck-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
.css-72eb3c-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
  background: transparent !important;
}

.css-fh01sf-MuiPickersYear-yearButton {
  background: #090909 !important;
}

.css-fh01sf-MuiPickersYear-yearButton.Mui-selected {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #d2c3b0 !important;
}

.css-13fhscp-MuiButtonBase-root-MuiIconButton-root {
  background: transparent !important;
  margin: 0 !important;
}

/* .css-1g7nc1s-MuiPickersLayout-root .MuiPickersLayout-toolbar {
    background: white !important;
} */

.css-1g7nc1s-MuiPickersLayout-root .MuiPickersLayout-actionBar {
  display: none;
}

.css-f4ql5f-MuiTypography-root-MuiDatePickerToolbar-title {
  color: #ffffff !important;
}

.css-6wwd73-MuiAutocomplete-noOptions {
  background-color: white;
}

.css-79n2c1::-webkit-scrollbar {
  display: none;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background: rgba(12, 11, 10, 0.1);
  backdrop-filter: blur(1px);
}

.css-10qnh7y-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #e11a1a;
}

.css-10qnh7y-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #e11a1a;
}

.p-calendar {
  /* width: 100% !important; */
}

.p-calendar .p-inputtext {
  width: 100% !important;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #282625;
  color: #ffffff;
}

.p-calendar .p-inputtext:focus {
  box-shadow: none;
}

.p-datepicker .p-datepicker-header,
.p-datepicker:not(.p-datepicker-inline) {
  background: #121212;
  position: relative;
}

.p-datepicker .p-datepicker-header {
  border-bottom: 1px solid #121212;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  color: white;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: white;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #df8b3b;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #df8b3b;
}

.p-datepicker table td > span {
  color: white;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  color: #df8b3b;
}

.p-datepicker table td > span.p-highlight {
  color: #df8b3b;
  background-color: white;
}

.p-datepicker table th > span {
  color: white;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  color: white;
}

.p-datepicker table td.p-datepicker-today > span {
  color: white;
  background: #df8b3b;
}

.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background-color: white;
  color: #df8b3b;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
  color: #fff;
}

.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  color: #df8b3b;
  background-color: white;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  color: white;
}

.p-datepicker {
  z-index: 1301 !important;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: none;
  /* padding: 0; */
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:nth-child(2) {
  border-left: 1px solid rgba(255, 255, 255, 1);
  /* padding: 0; */
}
.p-datepicker-multiple-month .p-datepicker-group-container {
  /* flex-direction: column; */
}
.p-datepicker table td {
  /* padding: 0; */
}
.p-datepicker td > span {
  margin: 4px;
}
.p-datepicker th > span {
  margin: 0;
}
.p-calendar .p-datepicker {
  min-width: auto;
}
@media (max-width: 1000px) {
  /* .p-datepicker table td > span {
    width: 1.5rem;
    height: 1.5rem;
  } */
  .p-datepicker-multiple-month .p-datepicker-group-container {
    flex-direction: column;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:nth-child(2) {
    border-left: none;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    /* border-left: none; */
    padding: 0;
  }
}
@media (max-width: 576px) {
  .css-jtcya9 div input {
    width: 30px !important;
    height: 30px !important;
  }

  .css-h5fkc8 a {
    font-size: 18px !important;
  }
  .p-datepicker table td > span {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-link {
    font-size: 0.8rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    padding: 0;
  }
}

@media (max-width: 428px) {
  .css-l8ymep-MuiInputBase-input-MuiOutlinedInput-input {
    width: 40px !important;
    height: 40px !important;
  }

  .css-h5fkc8 a {
    font-size: 16px !important;
  }
}

@media (max-width: 390px) {
  .css-l8ymep-MuiInputBase-input-MuiOutlinedInput-input {
    width: 30px !important;
    height: 30px !important;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media (max-width: 1440px) {
  iframe {
    height: 400px;
  }
}

@media (max-width: 599px) {
  .PhoneInput {
    width: 100%;
  }

  .PhoneInputCountry {
    padding: 0 10px;
    width: 100%;
  }
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-LightItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-Light.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-ExtraBold.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-MediumItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-ExtraBoldItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-Italic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-Medium.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-BoldItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-Bold.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-MediumItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-BoldItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-SemiBold.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-Medium.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-ExtraBoldItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-Italic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-LightItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-SemiBoldItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-Bold.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-Light.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-ExtraBold.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./resource/fonts/OpenSansCondensed-Regular.woff2') format('woff2'),
    url('./resource/fonts/OpenSansCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-SemiBoldItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-SemiBold.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-Bold.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiCondensed';
  src: url('./resource/fonts/OpenSansSemiCondensed-Regular.woff2') format('woff2'),
    url('./resource/fonts/OpenSansSemiCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-Italic.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-Light.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-BoldItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-SemiBold.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-Regular.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-LightItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-Medium.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./resource/fonts/OpenSans-MediumItalic.woff2') format('woff2'),
    url('./resource/fonts/OpenSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
